/* styles.css */

.spin {
  border-top-color: transparent;
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}