@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Pretendard', sans-serif;
}
body, #root {
  background-color: #0E0E11;
  min-height: 100vh;
}